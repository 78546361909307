export const URL_SERVICE = 'https://api-catalog.web.app/public/';
export const SESSION_INTERVAL = 10000;
export const LANDING_URL = 'https://easy-catalog.app';
export const LANDING_NOT_FOUND_URL = 'https://easy-catalog.app/404.html';

export const SESSION_TIME_KEY = (id: string) => `${id}_time`;
export const SESSION_KEY = 'identifier';
export const STORAGE_USER_KEY = 'user';
export const STORAGE_LAST_VISITS = (id: string) => `${id}_last`;

export const userIdentifier = (id: string): string => {
  const userKey = storageUserKey(id);
  let userValue = localStorage.getItem(userKey);

  if (userValue === null) {
    const value = generateUID(10);
    localStorage.setItem(userKey, value);

    userValue = value;
  }

  return userValue || id;
}

export const storageUserKey = (catalog: string): string => {
  return `${STORAGE_USER_KEY}_${catalog}`;
}

export const generateSessionKey = (_: string): string => {
  return `${generateUID(20)}`;
}

export const generateUID = (length: number): string =>{
    return window.btoa(Array.from(window.crypto.getRandomValues(new Uint8Array(length * 2)))
      .map((b) => String.fromCharCode(b))
      .join("")).replace(/[+/]/g, "")
      .substring(0, length);
}

export const cleanStringSpaces = (value: string) => value.replace(/\s/g, '-');