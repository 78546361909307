import React from 'react';
import { RouteComponentProps, withRouter } from "react-router-dom";

interface PageHeaderProps {
  href: string;
  children: React.ReactNode;
}

const LinkText = withRouter((props: RouteComponentProps & PageHeaderProps) => {
  return (
    <div onClick={() => props.history.push(props.href)}>
      {props.children}
    </div>
  );
});

export default LinkText;
