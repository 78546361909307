import React from 'react';

import { RouteComponentProps, withRouter } from "react-router-dom";
import { cleanStringSpaces } from '../../data/config';
import { CatalogDTO } from '../../models/Catalog';

import LinkText from '../LinkText/LinkText';
import './PageHeader.css';
interface PageHeaderProps {
  catalog?: CatalogDTO;
}

const PageHeader = withRouter((props: RouteComponentProps & PageHeaderProps) => {
  const {catalog} = props;
  const routeBuilder = (section: string) => {
    return `/${catalog?.identifier}/${cleanStringSpaces(catalog?.title || '')}/${section}`;
  }

  return (
    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#F0F0F0', marginBottom: 20}}>
      <LinkText href={routeBuilder('')}>
        <p className={'CompanyNameLabel'}>{props.catalog?.company?.name}</p>
      </LinkText>

      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
        <LinkText href={routeBuilder('products/')}>
          <p style={{marginRight: 20}}>
            Products
          </p>
        </LinkText>

        <LinkText href={routeBuilder('contact/')}>
          <p style={{marginRight: 20}}>
            Contact
          </p>
        </LinkText>
      </div>
    </div>
  );
});

export default PageHeader;
