import { IonImg } from '@ionic/react'
import React, { PureComponent } from 'react'

export interface LoadingImageComponentProps {
  imageUrl: string;
  onClick?: () => void; 
  style: { [key: string]: any; } | undefined;
}

type ImageLoadingStatus = 'INIT' | 'LOADING' | 'LOADED' | 'ERROR';

interface LoadingImageComponentState {
  status: ImageLoadingStatus;
}

export class LoadingImageComponent extends PureComponent<LoadingImageComponentProps, LoadingImageComponentState> {
  constructor(props: any) {
    super(props);

    this.state = {
      status: 'INIT'
    }
  }

  public render() {
    const imageUri = require('./assets/loading.gif');

    return (
      <div onClick={this.props.onClick}>
        <IonImg
          style={{width: 150, height: 150}}
          hidden={this.state.status === 'LOADED'}
          src={imageUri}
        />
        <IonImg
          onIonError={this.setStatus('ERROR')}
          onIonImgDidLoad={this.setStatus('LOADED')}
          onIonImgWillLoad={this.setStatus('LOADING')}
          style={{...this.props.style}}
          src={this.props.imageUrl}
        />
      </div>
    )
  }

  private setStatus = (value: ImageLoadingStatus) => () => {
    console.log('Image status change', value);

    this.setState({status: value})
  }
}

export default LoadingImageComponent
