import React from 'react';

interface ToogleButtonProps {
  isSelected: Boolean;
  title: string;
  toggle: () => void;
}
  
const ToggleButton = ({isSelected, toggle, title}: ToogleButtonProps) => {
  const color = isSelected ? '#40A944' : '#222222';
  const borderColor = isSelected ? '#40A944' : '#222222';

  return (
    <div
      onClick={toggle}
      style={{
        margin: 4,
        userSelect: 'none',
        padding: 4,
        borderRadius: 6,
        display: 'flex',
        borderWidth: 1,
        borderColor: borderColor,
        borderStyle: 'solid',
        cursor: 'pointer'
      }}
    >
      <span style={{color, fontWeight: 500, textTransform: 'capitalize'}}>{title}</span>
    </div>
  );
}

export default ToggleButton;
