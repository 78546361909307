import { IonIcon } from "@ionic/react";
import { logoFacebook, logoTwitter } from 'ionicons/icons';
import React from "react";

export const ShareIcons = () => {
  return (
    <div>
      <div style={{display: 'flex'}}>
        <a href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`} style={{textDecoration: 'none'}}>
          <div
            style={{
              display: 'flex',
              backgroundColor: '#3B5999',
              color: 'white',
              alignItems: 'center',
              padding: 8,
              borderRadius: 4,
              margin: 6
            }}
          >
            <IonIcon icon={logoFacebook} style={{margin: 2}} slot={'end'} /> Share
          </div>
        </a>

        <a href={`https://twitter.com/intent/tweet?text=${window.location.href}`} style={{textDecoration: 'none'}}>
          <div 
            style={{
              display: 'flex',
              backgroundColor: '#1DA1F2',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
              padding: 8,
              borderRadius: 4,
              margin: 6
            }}>
            <IonIcon icon={logoTwitter} style={{margin: 2}} slot={'end'} />
            Tweet
          </div>
        </a>
      </div>
    </div>
  );
}