
import React, { useEffect, useState } from 'react';
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';

import { IonContent, IonIcon, IonPage, IonText } from '@ionic/react';
import { callOutline, location, logoWhatsapp } from 'ionicons/icons';
import { useParams } from 'react-router-dom';

import PageHeader from '../../components/PageHeader';
import { fetchCatalog } from '../../data/DataAccess';
import { OptioanlCatalogDTO } from '../../models/Catalog';

function formatPhoneNumber(phoneNumberString: string) {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = (match[1] ? '+1 ' : '');
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
}

const ContactPage: React.FC = () => {
  const urlParams = useParams() as any;
  const [catalogInfo, setCatalogInfo] = useState<OptioanlCatalogDTO>(undefined);

  useEffect(() => {
    fetchCatalog(urlParams.id)
      .then((item) => {
        setCatalogInfo(item);
      })
      .catch((error) => {
        console.error('Error ferching catalog', error);
      });
  }, [urlParams.id, urlParams.product]);

  const openWhatsApp = (phone: string) => {
    window.location.href = `https://api.whatsapp.com/send?phone=${phone}`;

    console.log('redirect!');
  }

  return (
    <IonPage>
      <IonContent fullscreen={true} style={{display: 'flex'}}>
        <PageHeader catalog={catalogInfo} />

        <div style={{width: '100%', height: 340, overflow: 'hidden', position: 'relative'}}>
          <MapComponent />
        </div>

        <div style={{width: '100%'}}>
          <div style={{textAlign: 'center'}}>
            <p style={{fontFamily: 'Poppins', fontSize: 24, margin: 0}}>{catalogInfo?.company.name}</p>
            <p style={{fontFamily: 'Poppins', fontSize: 16, margin: 0}}>{catalogInfo?.company.description}</p>
          </div>

          <div style={{display: 'flex', marginTop: 20, flexDirection: 'row', justifyContent: 'space-evenly'}} onClick={() => openWhatsApp(catalogInfo?.company.phone || '')}>
            <div style={{textAlign: 'center', width: '50%'}}>
              <IonIcon style={{fontSize: 60, color: '#333333', margin: 0}} icon={callOutline} />
              <p style={{fontSize: 24, fontWeight: 600, margin: 0, color: '#333333'}}>Phone</p>

              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 5}} onClick={() => openWhatsApp(catalogInfo?.company.phone || '')}>
                <IonIcon style={{fontSize: 20, color: '#333333', margin: 0}} icon={logoWhatsapp} />
                <IonText onClick={() => openWhatsApp(catalogInfo?.company.phone || '')}>
                  <div style={{fontSize: 16, fontWeight: 400, margin: 0, marginLeft: 5, color: '#333333'}} onClick={() => openWhatsApp(catalogInfo?.company.phone || '')}>{formatPhoneNumber(catalogInfo?.company.phone || '')}</div>
                </IonText>
              </div>
            </div>

            <div style={{textAlign: 'center', width: '50%'}}>
              <IonIcon style={{fontSize: 60, color: '#333333'}} icon={location} />
              <div style={{fontSize: 24, fontWeight: 600, margin: 0, color: '#333333'}}>Address</div>
              <p style={{fontSize: 16, fontWeight: 400, margin: 0, marginTop: 5, color: '#333333'}}>{catalogInfo?.company.address}</p>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

const MapComponent = GoogleApiWrapper({
  apiKey: 'AIzaSyAIc5LH22OyF2dfHaUchK6DqTnsh8roPso'
})((props: any) => {
  const [activeMarker, setActiveMarker] = useState<Marker | undefined>();

  return (
    <Map
      onReady={(_, map) => map.setOptions({zoom: 14})}
      google={props.google}
      style={{height: 300}}
      initialCenter={
        {
          lat: -1.2884,
          lng: 36.8233
        }
      }
      center={{latitude: 33.8688, longitude: 151.2093}}
    >
      <Marker
        mapCenter={{latitude: 33.8688, longitude: 151.2093}}
        onClick={(_, marker, __) => setActiveMarker(marker)}
      />
      <InfoWindow
        visible={activeMarker !== undefined}
        google={props.google}
        map={props}
        marker={activeMarker}
      >
        <div>
          <h4>{'Company name'}</h4>
        </div>
      </InfoWindow>
    </Map>
  );
});

export default ContactPage;
