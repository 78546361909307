import React, {useEffect, useRef, useState} from 'react';

import {
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonSearchbar,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import { chevronUpCircle } from 'ionicons/icons';

import ProductCard from '../../components/ProductCard';
import ToggleButton from '../../components/ToggleButton';
import PageHeader from '../../components/PageHeader';

import { filtersProducts, fetchCatalog, trackSession, trackUserVisits } from '../../data/DataAccess';
import { useParams } from 'react-router-dom';
import { OptioanlCatalogDTO } from '../../models/Catalog';
import { LANDING_NOT_FOUND_URL, LANDING_URL, SESSION_INTERVAL } from '../../data/config';

import './Home.css';

type SortType = 'PRICE_DESC' | 'PRICE_ASC' | 'NAME';

const HomePage: React.FC = () => {
  const urlParams = useParams() as any;
  const [catalogInfo, setCatalogInfo] = useState<OptioanlCatalogDTO>(undefined);

  if (urlParams.id === undefined) {
    if (__DEV__ === false) {
      window.location.assign(LANDING_URL);
    }
  }

  useEffect(() => {
    fetchCatalog(urlParams.id).then((item) => {
      setCatalogInfo(item);
    })
    .catch((error) => {
      console.error('Error fetching catalog', error);

      if (__DEV__ === false) {
        window.location.assign(LANDING_NOT_FOUND_URL);
      }
    });
  }, [urlParams.id]);

  useEffect(() => {
    // Track user visits
    trackUserVisits(urlParams.id);

    const intervalReference = setInterval(() => {
      if (window.document.hasFocus() === false) {
        return
      }

      // Track user session
      trackSession(urlParams.id);
    }, SESSION_INTERVAL);

    return () => {
      clearInterval(intervalReference);
    }
  }, [urlParams.id])

  const products = (catalogInfo?.products || []);
  const tags = products.flatMap((item) => item.tags).filter((item) => item !== undefined && item.trim().length > 0).reduce((prev, tag) => {
    let total = 1;

    if (prev[tag] !== undefined) {
      total = prev[tag] + 1
    }

    return {
      ...prev,
      [tag]: total
    }
  }, {} as {[key: string]: number});

  const initialValue: string[] = [];
  const [itemsSelected, setItemsSelected] = useState(initialValue);

  const toggleOption = (value: string) => () => {
    if (itemsSelected.indexOf(value) === -1) {
      setItemsSelected([...itemsSelected, value])
    } else {
      setItemsSelected([...itemsSelected.filter((item) => item !== value)])
    }
  }

  const isSelected = (value: string) => {
    return itemsSelected.indexOf(value) !== -1;
  }

  const contentRef = useRef<HTMLIonContentElement | null>(null);
  const scrollToTop= () => {
    contentRef.current && contentRef.current.scrollToTop(1200);
  };

  const [searchValue, setSearchValue] = useState('');
  const selectedProducts = filtersProducts(products, itemsSelected, searchValue);

  return (
    <IonPage id={'main'}>
      <PageHeader catalog={catalogInfo} />

      <IonContent ref={contentRef} scrollEvents={true}>
        <div
          style={{
            display: 'flex',
            marginLeft: 15,
            marginRight: 15,
            flexWrap: 'wrap'
          }}
        >
          {Object.entries(tags).map((item, index) => {
            const title = `${item[0]} (${item[1]})`;

            return (
              <ToggleButton
                title={title}
                key={index}
                isSelected={isSelected(String(item[0]))}
                toggle={toggleOption(String(item[0]))}
              />
            );
          })}
        </div>

        <IonGrid>
          <IonRow className="ion-align-items-start">
            <IonCol size={'8'}>
              <IonSearchbar
                value={searchValue}
                onIonChange={e => setSearchValue(e.detail.value || '')} 
              />
            </IonCol>

            <IonCol size={'4'}>
              <IonItem>
                <IonLabel>Sort by</IonLabel>
                <IonSelect value={'test'}>
                  <IonSelectOption value={'PRICE_DESC'}>Price Desc</IonSelectOption>
                  <IonSelectOption value={'PRICE_ASC'}>Price Asc</IonSelectOption>
                </IonSelect>
              </IonItem>
            </IonCol>
          </IonRow>
        </IonGrid>

        <div style={{minHeight: 400}}>
          <IonGrid>
            <IonRow>
              {selectedProducts.map((product, index) => {
                return (
                  <IonCol
                    key={index}
                    sizeLg={'4'}
                    sizeMd={'3'}
                    sizeXs={'12'}
                    sizeSm={'6'}
                    size={'3'}
                    sizeXl={'3'}
                  >
                    <ProductCard
                      product={product}
                      index={index}
                      catalogId={catalogInfo?.identifier || ""}
                      catalogTitle={catalogInfo?.title || ""}
                    />
                  </IonCol>
                );
              })}
            </IonRow>
          </IonGrid>
        </div>

        <div style={{height: 80, width: '100%', backgroundColor: '#333333', marginTop: 100}}></div>

        <IonFab vertical="bottom" horizontal="end" slot="fixed" onClick={scrollToTop}>
          <IonFabButton>
            <IonIcon icon={chevronUpCircle} />
          </IonFabButton>
        </IonFab>
      </IonContent>
    </IonPage>
  );
};

export default HomePage;
