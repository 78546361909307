import React, { useEffect, useState } from 'react';

import { IonContent, IonPage } from '@ionic/react';
import { useParams } from 'react-router-dom';
import { OptioanlCatalogDTO } from '../../models/Catalog';
import { fetchCatalog } from '../../data/DataAccess';
import { ProductItem } from '../../models/Product';
import { bigImage, smallImage } from '../../data/UrlImageHelper';

import PageHeader from '../../components/PageHeader';
import LoadingImageComponent from '../../components/LoadingImage/LoadingImageComponent';
import ToggleButton from '../../components/ToggleButton';
import { ShareIcons } from './ShareIcons';

const DetailsProduct: React.FC = () => {
  const urlParams = useParams() as any;
  const [catalogInfo, setCatalogInfo] = useState<OptioanlCatalogDTO>(undefined);
  const [selectedImage, setSelectedImage] = useState<string>('');
  const [product, setProduct] = useState<ProductItem | undefined>(undefined);

  console.log('Url params', urlParams);

  useEffect(() => {
    fetchCatalog(urlParams.id)
      .then((item) => {
        setCatalogInfo(item);

        const product: ProductItem | undefined = item?.products[urlParams.product - 1];
        const image = product?.images[0] || '';

        setProduct(product);
        setSelectedImage(image)
      })
      .catch((error) => {
        console.error('Error ferching catalog', error);
      });
  }, [urlParams.id, urlParams.product]);

  return (
    <IonPage>
      <IonContent fullscreen={true} style={{display: 'flex'}}>
        <PageHeader catalog={catalogInfo} />

        <div style={{display: 'flex', justifyContent: 'center', flexWrap: 'wrap'}}>
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <LoadingImageComponent style={{width: 600, height: 600, borderWidth: 1, borderColor: '#cfcfcf', borderStyle: 'solid'}} imageUrl={bigImage(selectedImage)} />

            <div style={{display: 'flex', justifyContent: 'center'}}>
              {
                (product?.images || [])
                  .map((image) => {
                    const isSelected = selectedImage === image;
                    const selectedColor = isSelected === false ? '#F0F0F0' : '#555';
                    return <LoadingImageComponent
                      onClick={() => setSelectedImage(image)}
                      style={{width: 100, height: 100, margin: 10, borderWidth: 2, borderColor: selectedColor, borderStyle: 'solid'}}
                      imageUrl={smallImage(image)}
                    />
                  })
              }
            </div>
          </div>

          <div style={{display: 'flex', flexDirection: 'column', marginLeft: 20, marginRight: 20, minWidth: 250, maxWidth: 350}}>
            <p style={{fontSize: '25px', margin: 0, fontFamily: 'Poppins'}}>{product?.name}</p>
            <p style={{fontFamily: 'Poppins', margin: 0,}}>
              {`$${product?.price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') || '$0.00'}`}
            </p>

            <p style={{fontFamily: 'Poppins', margin: 0, marginTop: 5, marginBottom: 10}}>{product?.description || ''}</p>

            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap'
              }}
            >
              {Object.entries(product?.tags || []).map((item, index) => {
                const title = item[1];

                return (
                  <ToggleButton
                    title={title}
                    key={index}
                    isSelected={false}
                    toggle={() => {}}
                  />
                );
              })}
            </div>

            <ShareIcons />

          </div>
        </div>
        
        <div style={{height: 80, width: '100%', backgroundColor: '#333333', marginTop: 30}}></div>
      </IonContent>
    </IonPage>
  );
};

export default DetailsProduct;
