import React from 'react';
import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonChip, IonItem } from "@ionic/react";
import { ProductItem } from '../../models/Product';
import { mediumImage } from '../../data/UrlImageHelper';
import LoadingImageComponent from '../LoadingImage/LoadingImageComponent';
import { cleanStringSpaces } from '../../data/config';

interface ProctCardProps {
  catalogId: string;
  catalogTitle: string;
  product: ProductItem;
  index: number;
}

const ProductCard = ({catalogId, product, index, catalogTitle}: ProctCardProps) => {
  const imageUrl = mediumImage(product.images[0]);

  return (
    <div style={{maxWidth: 'min-content'}}>
      <IonItem detail={false} lines={'none'} routerLink={`/${catalogId}/${cleanStringSpaces(catalogTitle)}/product/${index + 1}/${cleanStringSpaces(product.name)}/`}>
        <IonCard>
          <div style={{width: 400, height: 200, overflow: 'hidden'}}>
            <LoadingImageComponent
              style={{width: '100%', height: '100%', objectFit: 'cover'}}
              imageUrl={imageUrl}
            />
          </div>
          
          <IonCardHeader>
            <IonCardTitle>{product.name}</IonCardTitle>
            <IonCardSubtitle>${product.price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent>
            {product.tags.map((tag, index) => {
              return <IonChip key={index}>{tag}</IonChip>
            })}
          </IonCardContent>
        </IonCard>
      </IonItem>
    </div>
  );
}

export default ProductCard;