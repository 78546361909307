import { CatalogDTO } from "../models/Catalog";
import { ProductItem } from "../models/Product";
import { generateSessionKey, SESSION_KEY, SESSION_TIME_KEY, STORAGE_LAST_VISITS, URL_SERVICE, userIdentifier } from "./config";

const urlService = URL_SERVICE;

export const saveSession = (sessionId: string, catalogId: string, userId: string) => {
  const data = {
    sessionId,
    catalogId,
    userId
  }

  const url = `${urlService}catalog/${catalogId}/session/`;

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then((response) => {
    console.log('Session response', response.statusText);
  });
}


export const saveVisits = (catalogId: string, userId: string) => {
  const url = `${urlService}catalog/${catalogId}/visits/${userId}`;

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    }
  });
}

export const fetchCatalog = (identifier: string): Promise<CatalogDTO> => {
  const url = `${urlService}catalog/${identifier}`;

  return fetch(url)
    .then((response) => {
      if (response.status !== 200) {
        return Promise.reject(response.text);
      }

      return response.json();
    })
    .then((json) => {
      return json.content;
    });
}

const areCommonElements = (arr1: string[], arr2: string[]) => {
  const arr2Set = new Set(arr2);
  return arr1.some(el => arr2Set.has(el));
};

export const filtersProducts = (products: ProductItem[], selectedTags: string[], searchCriteria: string): ProductItem[] => {
  if (selectedTags.length === 0 && searchCriteria.length === 0) {
    return products;
  }

  let results: ProductItem[] = [];

  if (selectedTags.length > 0) {
    results = products.filter((item) => areCommonElements(item.tags, selectedTags));
  }

  if (searchCriteria.length > 0) {
    results = results.filter((item) => item.name.toLowerCase().search(searchCriteria.toLowerCase()) !== -1);
  }

  return results;
}

export const trackUserVisits = (catalogId: string) => {
  const lastVisits = localStorage.getItem(STORAGE_LAST_VISITS(catalogId));
  let user = userIdentifier(catalogId);

  // Set visits
  const minIntervalForVisits = 3600 * 6 * 1000;
  
  if (lastVisits === null || (Date.now() - parseInt(lastVisits)) > minIntervalForVisits) {
    saveVisits(catalogId, user)
      .then(() => {
        localStorage.setItem(STORAGE_LAST_VISITS(catalogId), Date.now().toString())
      });
  }
}

export const trackSession = (catalogId: string) => {
  let user = userIdentifier(catalogId);

  let sessionTimeKey = SESSION_TIME_KEY(catalogId);
  let initial = sessionStorage.getItem(sessionTimeKey);
  let identifier = sessionStorage.getItem(SESSION_KEY);
  
  // After 5 minutes of inactivity reset the session
  if (initial === null || (Date.now() - parseInt(initial)) > 300 * 1000) {
    const sessionKey = generateSessionKey(catalogId);
    sessionStorage.setItem(sessionTimeKey, Date.now().toString());
    sessionStorage.setItem(SESSION_KEY, sessionKey);

    identifier = sessionKey;
  }

  const now = Date.now();
  sessionStorage.setItem(sessionTimeKey, now.toString());

  // Save session
  saveSession(identifier || '', catalogId, user);
}